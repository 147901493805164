<template>
  <div class="monit">
    <!-- 默认页面 -->
    <el-row v-if="flag == 1">
      <el-row :gutter="4" class="elRow" style="display: flex">
        <el-col :span="3">
          <el-input
            size="small"
            v-model.trim="name"
            :placeholder="$t('AppManage.SDKMonitorPage.placeholder')"
            @keyup.enter.native="onSubmit"
          ></el-input>
        </el-col>
        <el-col :span="3">
          <el-button size="small" type="primary" v-debounce:500="onSubmit">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>

      <el-row :gutter="4" class="elRow">
        <el-col class="butGruop">
          <el-button
            type="primary"
            style="
              cursor: pointer;
              border-top-right-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
            "
            size="small"
            @click="add"
            >{{ $t("public.New") }}</el-button
          >
          <el-dropdown @command="handleCommand">
            <el-button
              type="primary"
              placement="bottom-start"
              style="
                cursor: pointer;
                border-radius: 0px !important;
                margin: 0px 1px;
              "
              size="small"
              >{{ $t("public.Toleadinto") }}</el-button
            >
            <el-dropdown-menu slot="dropdown" class="dropdownMenuBox">
              <el-dropdown-item command="a">{{
                $t("AppManage.SDKMonitorPage.DownTemplate")
              }}</el-dropdown-item>
              <el-dropdown-item command="b">{{
                $t("AppManage.SDKMonitorPage.ImportSDKPermission")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button
            style="border-radius: 0px !important; cursor: pointer"
            type="primary"
            size="small"
            @click="resetForm('dynamicValidateForm')"
            >{{ $t("public.Derive") }}</el-button
          >
          <el-button
            style="
              border-radius: 0px 3px 3px 0px;
              margin: 0px 0px 0px 0px;
              margin-left: 1px;
            "
            @click="delArr(delarr)"
            :type="typeInfo"
            :disabled="!hasSeleted"
            size="small"
            >{{ $t("public.Delete") }}</el-button
          >
        </el-col>
      </el-row>
      <!-- 表格          :row-key="getRowKey" :header-cell-style="{ background: '#eef1f6', color: '#606266' }"-->
      <el-row :gutter="4" class="elRow">
        <el-table
          size="small"
          :row-key="getRowKeys"
          stripe
          fit
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          :default-sort="{ prop: 'updateTime', order: 'descending' }"
          :data="tableData"
          ref="multipleTable"
          tooltip-effect="dark"
          class="tableClass"
          @select="checkChange"
          @selection-change="delobj"
          @sort-change="handleSortChange"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            align="center"
            width="60"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            width="180"
            prop="name"
            :label="$t('AppManage.SDKMonitorPage.SDKName')"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            width="180"
            prop="content.packageName"
            :label="$t('AppManage.SDKMonitorPage.SDKPackageName')"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <!-- <el-table-column prop="group.parentName" label="部门">
          </el-table-column> -->
          <el-table-column
            width="180"
            sortable="custom"
            prop="updateTime"
            :label="$t('public.ModificationTime')"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column prop="active" :label="$t('public.Controls')">
            <template slot-scope="scope">
              <img
                @click="edit(scope.row)"
                :title="$t('public.Editor')"
                src="@/assets/icon_write.png"
                alt=""
                style="width: 15px; height: 15px; cursor: pointer"
              />

              <img
                @click="details(scope.row.id)"
                :title="$t('public.Delete')"
                src="@/assets/icon_del.png"
                alt=""
                style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  cursor: pointer;
                "
              />
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页-->
        <pagination
          :page="page.curPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </el-row>
    </el-row>

    <!-- <div > -->
    <sdk-pages v-if="flag == 2" @sdkSave="sdkSave" :editData="editData" />
    <!-- </div> -->

    <!-- 导入页面 -->
    <sdk-import v-if="flag == 4" @sdkSave="sdkSave" :editData="editData" />
    <!-- 保存成功的弹框 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import naviGation from "@/components/hearder/index"; //面包屑
import dialogInfo from "@/components/promptMessage/index";
import pagination from "@/components/pagination/page";
import sdkPages from "./sdk/sdkPages.vue"; //新增 编辑
import SdkImport from "./sdk/sdkImport.vue"; //导入
export default {
  components: { pagination, dialogInfo, naviGation, sdkPages, SdkImport },
  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      userId: "1",
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: "", // 提示框的提示语
      //所有页全选
      allPagesSelet: false,
      //页面切换标识
      flag: 1,
      // monitoring
      page: {
        pageSize: 10, //每页条数,  默认10条
        total: 0, //总条数
        curPage: 1, //当前页
        orderRule: "DESC",
        orderColume: "updateTime", //排序字段
      },
      tableData: [],
      editData: null, //编辑
      checkAll: false,
      isIndeterminate: false,
      // 全选弹框
      isCheckShow: false,
      // 全选框双向绑定
      checked: false,
      name: "", //查询名称

      // -----------------
      //删除弹框
      // deMackShow: false,
      multipleSelection: [],
      checkArray: [],
      delarr: [], //选中数据的id
      // ------------------------
      // 编辑ID
      ID: "",
      //编辑新增页面
      ruleForm: {
        id: "1",
        name: "",
        content: {
          packageName: "",
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("AppManage.SDKMonitorPage.SDKName"),
            trigger: "blur",
          },
        ],
        content: {
          packageName: [
            {
              required: true,
              message: this.$t("AppManage.SDKMonitorPage.SDKPackageName"),
              trigger: "blur",
            },
          ],
        },
      },
      // 删除按钮
      disabled: true,
      list: [
        {
          name: "S_SMS_ACCESS",
          //读取短信记录
          forbid: false, //禁止 1-勾选 0-未勾选， 以下同
          collect: false, //采集行为 16-勾选 0-未勾选， 以下同
          action: "0", //响应动作 4-告警提示，8-清除数据， 2-退出应用， 以下同
        },
        {
          name: "S_CONTACTS_ACCESS",
          //读取通讯录
          forbid: false,
          collect: false,
          action: "0",
        },
        {
          name: "S_LOCATION",
          //获取地理位置
          forbid: false,
          collect: false,
          action: "0",
        },
        {
          name: "S_GET_SDCARD",
          //读取外部存储
          forbid: false,
          collect: false,
          action: "0",
        },
        {
          name: "S_GET_DEVICE_INFO",
          //获取设备硬件信息
          forbid: false,
          collect: false,
          action: "0",
        },
        {
          name: "S_GET_INSTALLED_APP",
          //获取应用安装列表
          forbid: false,
          collect: false,
          action: "0",
        },
        {
          name: "S_CAMERA",
          //拍照、摄像
          forbid: false,
          collect: false,
          action: "0",
        },
        {
          name: "S_WIFI_SWITCH",
          //开启WIFI
          forbid: false,
          collect: false,
          action: "0",
        },
        {
          name: "S_BLUETOOTH_SWITCH",
          //开启蓝牙
          forbid: false,
          collect: false,
          action: "0",
        },
      ],
      addArray: [],
      addObject: {},

      isDisabled: false,

      fileList: [],
      filename: "",
    };
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },
  mounted() {
    this.getSDKMonitList();
  },
  methods: {
    sdkSave(val) {
      if (val == 1) {
        // this.visibaelFlag = true
        // this.titleInfo = '编辑成功'
        this.flag = 1;
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.name = "";
        this.getSDKMonitList();
      } else if (val == -1) {
        this.flag = 2;
        this.$message({
          message: this.$t("AppManage.SDKMonitorPage.SDKPackageNameVoid"),
          type: "error",
          offset: 100,
        });
      }
    },
    edit(val) {
      this.flag = 2;
      this.editData = val;
    },
    add() {
      this.flag = 2;
      this.editData = null;
    },
    getRowKeys(row) {
      return row.id;
    },
    //单选框默认不勾选
    checkboxRadio() {},
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },

    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.curPage = page;
      this.page.pageSize = limit;
      // 调用查询方法
      this.getSDKMonitList();
    },
    //监听排序
    handleSortChange(column) {
      console.log(column.order, column.prop, "kklklk");
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      this.onSubmit();
    },
    //获取列表默认倒序
    async getSDKMonitList() {
      var params = {
        currentPage: this.page.curPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        name: this.name,
        type: "sdk",
      };

      console.log(params, "SDK监控列表参数");
      const res = await this.$axios.post(
        "/httpServe/monitorExSDKRule/getDataInfo",
        params,
        true
      );
      console.log(res, "/获取列表默认倒序");
      this.tableData = res.data.content;
      this.page.total = res.data.total;
    },

    //查询
    async onSubmit() {
      this.page.curPage = 1;
      var params = {
        currentPage: this.page.curPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        name: this.name,
        type: "sdk",
      };

      console.log(params, "SDK监控列表参数");
      const res = await this.$axios.post(
        "/httpServe/monitorExSDKRule/getDataInfo",
        params,
        true
      );
      console.log(res, "/获取列表默认倒序");
      this.tableData = res.data.content;
      this.page.total = res.data.total;
      for (var j = 0; j < this.tableData.length; j++) {
        var obj2 = this.tableData[j];
        // console.log(obj2, "obj2");
        for (var key in obj2) {
          if (obj2[key] == "0") {
            obj2[key] = "N/A";
          }
          // console.log(obj2[key], "---------------");
        }
      }
    },
    checkChange(selection, row) {
      let arr1 = [];
      selection.forEach((item) => {
        arr1.push(item.id);
        //去重
        this.delarr = [...new Set(arr1)];
      });
    },
    delobj(obj) {
      this.multipleSelection = obj;
      this.delarr = obj;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },

    // 删除单条
    details(id) {
      // console.log(id);
      var params = {
        ids: [id],
      };
      // console.log(params, "单选");

      this.$confirm(
        this.$t("AppManage.SDKMonitorPage.sureDeleteFile"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios
            .post("/httpServe/monitorExSDKRule/delete", params, true)
            .then(async () => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.$refs.multipleTable.clearSelection();
              this.getSDKMonitList();
            })
            .catch(() => {
              this.$message({
                message: this.$t("AppManage.SDKMonitorPage.SaveFailed"),
                type: "error",
                offset: 100,
              });
              return false;
            });
        })
        .catch(() => {
          return false;
        });
    },
    //多选删除
    delArr(obj) {
      var ids = [];
      this.delarr.forEach((item) => {
        ids.push(item.id);
      });
      var params = {
        ids: ids,
      };

      this.$confirm(
        this.$t("AppManage.SDKMonitorPage.sureDeleteFile"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios
            .post("/httpServe/monitorExSDKRule/delete", params, true)
            .then(async () => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.$refs.multipleTable.clearSelection();
              this.getSDKMonitList();
            })
            .catch(() => {
              this.$message({
                message: this.$t("AppManage.SDKMonitorPage.SaveFailed"),
                type: "success",
                offset: 100,
              });
              return false;
            });
        })
        .catch(() => {
          return false;
        });
    },

    // 每页显示多少条
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getSDKMonitList();
    },
    //翻页
    handleCurrentChange(val) {
      this.page.curPage = val;
      this.getSDKMonitList();
    },
    // 导入SDK权限策略库tankaung
    handleCommand(command) {
      if (command == "a") {
        this.downModel();
      } else {
        this.flag = 4; //导入SDK权限策略库
      }
    },
    // 下载模板
    async downModel() {
      var params = {
        path: "public/模板文档/SDK监控模板.xls",
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        params,
        true
      );
      console.log(res, "下载模板");
      await this.$axios.downloadFile(res.data);
    },
    // 导出
    async resetForm() {
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE3NTczMTU3NDMsInVzZXJJZCI6MiwiaWF0IjoxNjQ5MzE1NzQzfQ.ig-bUpmMVNvC1L5XAoVmC_wIWJD-gG_L1l8NnQLTJwTZ-2hkJP1eBYtc3qF457bbvjtWKMWoXVz07ots4Faa5A";

      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );
      this.userId = JSON.parse(userString).userId;
      var params = {
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        groupId: "",
        type: "sdk",
        userId: 1,
        // userId:this.userId
      };
      console.log(params, "sdk监控导出参数");
      const res = await this.$axios.postExport(
        "/httpManageExport/monitorExSDKRule/exportFile",
        params,
        false,
        this.userId
      );
      console.log(res, "sdk导出");
      const link = document.createElement("a");
      console.info(new Date());

      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = "exportSDKRule_" + Date.parse(new Date()) + ".xls";
      // console.log(fileName);
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // location.href = '/api/monitorex/rule/sdk/exportFile.do'
    },
    // // 关闭提示框
    handleClose() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false; //提示消息  关闭
    },
  },
};
</script>

<style lang="scss" scoped>
.monit {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
  position: relative;
  .elRow {
    margin-bottom: 10px;
  }
  // 导入
  .import {
    padding: 25px 60px;
  }
  .import .importPage {
    display: flex;
    align-items: flex-start;
    .el-button {
      margin-right: 20px;
    }
  }
  .input1 {
    width: 200px;
  }
  // 导出
  .export {
    padding: 20px;
  }
}
.left {
  // z-index: 999;
  // position: fixed;
  // left: 100px;
  top: 150px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
<style lang="scss">
.delBut {
  cursor: not-allowed !important;
}
//导入下拉框
.dropdownMenuBox {
  font-size: 14px;
  z-index: 9999 !important;
}
</style>
