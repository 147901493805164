<template>
  <div style="width: 100%; margin-left: 60px; margin-top: 25px">
    <el-form
      :model="ruleForm"
      size="small"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item :label="$t('AppManage.SDKMonitorPage.SDKName')+'：'"    prop="name">
        <el-input  v-model="ruleForm.name" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item  :label="$t('AppManage.SDKMonitorPage.SDKPackageName')+'：'"   prop="packageName">
        <el-input
          v-model="ruleForm.packageName"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('AppManage.SDKMonitorPage.SDKPermissInfo')+'：'"  required: true  :required="true" class="sdk_Permissions">
        <el-form-item :label="$t('AppManage.SDKMonitorPage.ReadingSMSRecords')+'：'">
          <el-checkbox
            style="margin-left: 15px"
            true-label="1"
            false-label="0"
            v-model="list.S_SMS_ACCESS.forbid"
            >{{ $t("AppManage.SDKMonitorPage.prohibit") }}</el-checkbox
          >
          <el-checkbox
            style="margin-left: 15px"
            true-label="16"
            false-label="0"
            v-model="list.S_SMS_ACCESS.collect"
            >{{ $t("AppManage.SDKMonitorPage.CollectBehavior") }}</el-checkbox
          >
          <span style="margin-left: 30px; color: #606266">{{ $t("AppManage.SDKMonitorPage.ResponseAction") +'：'}}</span>
          <el-radio-group
            style="margin-left: 30px"
            v-model="list.S_SMS_ACCESS.action"
            @change="checkbox"
          >
            <el-radio :label="'4'" @click.native.prevent="checkboxRadio1('4')"
              >{{ $t("AppManage.SDKMonitorPage.AlarmPrompt") }}</el-radio
            >
            <el-radio :label="'8'" @click.native.prevent="checkboxRadio1('8')"
              >{{ $t("AppManage.SDKMonitorPage.ClearData") }}</el-radio
            >
            <el-radio :label="'2'" @click.native.prevent="checkboxRadio1('2')"
              >{{ $t("AppManage.SDKMonitorPage.ExitApp") }}</el-radio
            >
            <el-radio :label="'64'"  @click.native.prevent="checkboxRadio1('64')">{{ $t("AppManage.SDKMonitorPage.ProhibitAccess") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('AppManage.SDKMonitorPage.ReadAddressBook')+'：'">
          <el-checkbox
            style="margin-left: 15px"
            true-label="1"
            false-label="0"
            v-model="list.S_CONTACTS_ACCESS.forbid"
            >{{ $t("AppManage.SDKMonitorPage.prohibit") }}</el-checkbox
          >
          <el-checkbox
            style="margin-left: 15px"
            true-label="16"
            false-label="0"
            v-model="list.S_CONTACTS_ACCESS.collect"
            >{{ $t("AppManage.SDKMonitorPage.CollectBehavior") }}</el-checkbox
          >
          <span style="margin-left: 30px; color: #606266">{{ $t("AppManage.SDKMonitorPage.ResponseAction") +'：'}}</span>
          <el-radio-group
            style="margin-left: 30px"
            v-model="list.S_CONTACTS_ACCESS.action"
            @change="checkbox"
          >
            <el-radio :label="'4'" @click.native.prevent="checkboxRadio2('4')"
              >{{ $t("AppManage.SDKMonitorPage.AlarmPrompt") }}</el-radio
            >
            <el-radio :label="'8'" @click.native.prevent="checkboxRadio2('8')"
              >{{ $t("AppManage.SDKMonitorPage.ClearData") }}</el-radio
            >
            <el-radio :label="'2'" @click.native.prevent="checkboxRadio2('2')"
              >{{ $t("AppManage.SDKMonitorPage.ExitApp") }}</el-radio
            >
            <el-radio :label="'64'"  @click.native.prevent="checkboxRadio2('64')">{{ $t("AppManage.SDKMonitorPage.ProhibitAccess") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('AppManage.SDKMonitorPage.ObtainGeographicLocation')+'：'">
          <el-checkbox
            style="margin-left: 15px"
            true-label="1"
            false-label="0"
            v-model="list.S_LOCATION.forbid"
            >{{ $t("AppManage.SDKMonitorPage.prohibit") }}</el-checkbox
          >
          <el-checkbox
            style="margin-left: 15px"
            true-label="16"
            false-label="0"
            v-model="list.S_LOCATION.collect"
            >{{ $t("AppManage.SDKMonitorPage.CollectBehavior") }}</el-checkbox
          >
          <span style="margin-left: 30px; color: #606266">{{ $t("AppManage.SDKMonitorPage.ResponseAction") +'：'}}</span>
          <el-radio-group
            style="margin-left: 30px"
            v-model="list.S_LOCATION.action"
            @change="checkbox"
          >
            <el-radio :label="'4'" @click.native.prevent="checkboxRadio3('4')"
              >{{ $t("AppManage.SDKMonitorPage.AlarmPrompt") }}</el-radio
            >
            <el-radio :label="'8'" @click.native.prevent="checkboxRadio3('8')"
              >{{ $t("AppManage.SDKMonitorPage.ClearData") }}</el-radio
            >
            <el-radio :label="'2'" @click.native.prevent="checkboxRadio3('2')"
              >{{ $t("AppManage.SDKMonitorPage.ExitApp") }}</el-radio
            >
            <el-radio :label="'64'"  @click.native.prevent="checkboxRadio3('64')">{{ $t("AppManage.SDKMonitorPage.ProhibitAccess") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('AppManage.SDKMonitorPage.ReadExternalStorage')+'：'">
          <el-checkbox
            style="margin-left: 15px"
            true-label="1"
            false-label="0"
            v-model="list.S_GET_SDCARD.forbid"
            >{{ $t("AppManage.SDKMonitorPage.prohibit") }}</el-checkbox
          >
          <el-checkbox
            style="margin-left: 15px"
            true-label="16"
            false-label="0"
            v-model="list.S_GET_SDCARD.collect"
            >{{ $t("AppManage.SDKMonitorPage.CollectBehavior") }}</el-checkbox
          >
          <span style="margin-left: 30px; color: #606266">{{ $t("AppManage.SDKMonitorPage.ResponseAction") +'：'}}</span>
          <el-radio-group
            style="margin-left: 30px"
            v-model="list.S_GET_SDCARD.action"
            @change="checkbox"
          >
            <el-radio :label="'4'" @click.native.prevent="checkboxRadio4('4')"
              >{{ $t("AppManage.SDKMonitorPage.AlarmPrompt") }}</el-radio
            >
            <el-radio :label="'8'" @click.native.prevent="checkboxRadio4('8')"
              >{{ $t("AppManage.SDKMonitorPage.ClearData") }}</el-radio
            >
            <el-radio :label="'2'" @click.native.prevent="checkboxRadio4('2')"
              >{{ $t("AppManage.SDKMonitorPage.ExitApp") }}</el-radio
            >
            <el-radio :label="'64'"  @click.native.prevent="checkboxRadio4('64')">{{ $t("AppManage.SDKMonitorPage.ProhibitAccess") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('AppManage.SDKMonitorPage.ObtainDeviceHardwareInfo')+'：'">
          <el-checkbox
            style="margin-left: 15px"
            true-label="1"
            false-label="0"
            v-model="list.S_GET_DEVICE_INFO.forbid"
            >{{ $t("AppManage.SDKMonitorPage.prohibit") }}</el-checkbox
          >
          <el-checkbox
            style="margin-left: 15px"
            true-label="16"
            false-label="0"
            v-model="list.S_GET_DEVICE_INFO.collect"
            >{{ $t("AppManage.SDKMonitorPage.CollectBehavior") }}</el-checkbox
          >
          <span style="margin-left: 30px; color: #606266">{{ $t("AppManage.SDKMonitorPage.ResponseAction") +'：'}}</span>
          <el-radio-group
            style="margin-left: 30px"
            v-model="list.S_GET_DEVICE_INFO.action"
            @change="checkbox"
          >
            <el-radio :label="'4'" @click.native.prevent="checkboxRadio5('4')"
              >{{ $t("AppManage.SDKMonitorPage.AlarmPrompt") }}</el-radio
            >
            <el-radio :label="'8'" @click.native.prevent="checkboxRadio5('8')"
              >{{ $t("AppManage.SDKMonitorPage.ClearData") }}</el-radio
            >
            <el-radio :label="'2'" @click.native.prevent="checkboxRadio5('2')"
              >{{ $t("AppManage.SDKMonitorPage.ExitApp") }}</el-radio
            >
            <el-radio :label="'64'"  @click.native.prevent="checkboxRadio5('64')">{{ $t("AppManage.SDKMonitorPage.ProhibitAccess") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('AppManage.SDKMonitorPage.ObtainAppInsList')+'：'">
          <el-checkbox
            style="margin-left: 15px"
            true-label="1"
            false-label="0"
            v-model="list.S_GET_INSTALLED_APP.forbid"
            >{{ $t("AppManage.SDKMonitorPage.prohibit") }}</el-checkbox
          >
          <el-checkbox
            style="margin-left: 15px"
            true-label="16"
            false-label="0"
            v-model="list.S_GET_INSTALLED_APP.collect"
            >{{ $t("AppManage.SDKMonitorPage.CollectBehavior") }}</el-checkbox
          >
          <span style="margin-left: 30px; color: #606266">{{ $t("AppManage.SDKMonitorPage.ResponseAction") +'：'}}</span>
          <el-radio-group
            style="margin-left: 30px"
            v-model="list.S_GET_INSTALLED_APP.action"
            @change="checkbox"
          >
            <el-radio :label="'4'" @click.native.prevent="checkboxRadio6('4')"
              >{{ $t("AppManage.SDKMonitorPage.AlarmPrompt") }}</el-radio
            >
            <el-radio :label="'8'" @click.native.prevent="checkboxRadio6('8')"
              >{{ $t("AppManage.SDKMonitorPage.ClearData") }}</el-radio
            >
            <el-radio :label="'2'" @click.native.prevent="checkboxRadio6('2')"
              >{{ $t("AppManage.SDKMonitorPage.ExitApp") }}</el-radio
            >
            <el-radio :label="'64'"  @click.native.prevent="checkboxRadio6('64')">{{ $t("AppManage.SDKMonitorPage.ProhibitAccess") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('AppManage.SDKMonitorPage.photosVideos')+'：'">
          <el-checkbox
            style="margin-left: 15px"
            true-label="1"
            false-label="0"
            v-model="list.S_CAMERA.forbid"
            >{{ $t("AppManage.SDKMonitorPage.prohibit") }}</el-checkbox
          >
          <el-checkbox
            style="margin-left: 15px"
            true-label="16"
            false-label="0"
            v-model="list.S_CAMERA.collect"
            >{{ $t("AppManage.SDKMonitorPage.CollectBehavior") }}</el-checkbox
          >
          <span style="margin-left: 30px; color: #606266">{{ $t("AppManage.SDKMonitorPage.ResponseAction") +'：'}}</span>
          <el-radio-group
            style="margin-left: 30px"
            v-model="list.S_CAMERA.action"
            @change="checkbox"
          >
            <el-radio :label="'4'" @click.native.prevent="checkboxRadio7('4')"
              >{{ $t("AppManage.SDKMonitorPage.AlarmPrompt") }}</el-radio
            >
            <el-radio :label="'8'" @click.native.prevent="checkboxRadio7('8')"
              >{{ $t("AppManage.SDKMonitorPage.ClearData") }}</el-radio
            >
            <el-radio :label="'2'" @click.native.prevent="checkboxRadio7('2')"
              >{{ $t("AppManage.SDKMonitorPage.ExitApp") }}</el-radio
            >
            <el-radio :label="'64'"  @click.native.prevent="checkboxRadio7('64')">{{ $t("AppManage.SDKMonitorPage.ProhibitAccess") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('AppManage.SDKMonitorPage.EnableWIFI')+'：'">
          <el-checkbox
            style="margin-left: 15px"
            true-label="1"
            false-label="0"
            v-model="list.S_WIFI_SWITCH.forbid"
            >{{ $t("AppManage.SDKMonitorPage.prohibit") }}</el-checkbox
          >
          <el-checkbox
            style="margin-left: 15px"
            true-label="16"
            false-label="0"
            v-model="list.S_WIFI_SWITCH.collect"
            >{{ $t("AppManage.SDKMonitorPage.CollectBehavior") }}</el-checkbox
          >
          <span style="margin-left: 30px; color: #606266">{{ $t("AppManage.SDKMonitorPage.ResponseAction") +'：'}}</span>
          <el-radio-group
            style="margin-left: 30px"
            v-model="list.S_WIFI_SWITCH.action"
            @change="checkbox"
          >
            <el-radio :label="'4'" @click.native.prevent="checkboxRadio8('4')"
              >{{ $t("AppManage.SDKMonitorPage.AlarmPrompt") }}</el-radio
            >
            <el-radio :label="'8'" @click.native.prevent="checkboxRadio8('8')"
              >{{ $t("AppManage.SDKMonitorPage.ClearData") }}</el-radio
            >
            <el-radio :label="'2'" @click.native.prevent="checkboxRadio8('2')"
              >{{ $t("AppManage.SDKMonitorPage.ExitApp") }}</el-radio
            >
            <el-radio :label="'64'"  @click.native.prevent="checkboxRadio8('64')">{{ $t("AppManage.SDKMonitorPage.ProhibitAccess") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('AppManage.SDKMonitorPage.EnableBluetooth')+'：'">
          <el-checkbox
            style="margin-left: 15px"
            true-label="1"
            false-label="0"
            v-model="list.S_BLUETOOTH_SWITCH.forbid"
            >{{ $t("AppManage.SDKMonitorPage.prohibit") }}</el-checkbox
          >
          <el-checkbox
            style="margin-left: 15px"
            true-label="16"
            false-label="0"
            v-model="list.S_BLUETOOTH_SWITCH.collect"
            >{{ $t("AppManage.SDKMonitorPage.CollectBehavior") }}</el-checkbox
          >
          <span style="margin-left: 30px; color: #606266">{{ $t("AppManage.SDKMonitorPage.ResponseAction") +'：'}}</span>
          <el-radio-group
            style="margin-left: 30px"
            v-model="list.S_BLUETOOTH_SWITCH.action"
            @change="checkbox"
          >
            <el-radio :label="'4'" @click.native.prevent="checkboxRadio9('4')"
              >{{ $t("AppManage.SDKMonitorPage.AlarmPrompt") }}</el-radio
            >
            <el-radio :label="'8'" @click.native.prevent="checkboxRadio9('8')"
              >{{ $t("AppManage.SDKMonitorPage.ClearData") }}</el-radio
            >
            <el-radio :label="'2'" @click.native.prevent="checkboxRadio9('2')"
              >{{ $t("AppManage.SDKMonitorPage.ExitApp") }}</el-radio
            >
            <el-radio :label="'64'"  @click.native.prevent="checkboxRadio9('64')">{{ $t("AppManage.SDKMonitorPage.ProhibitAccess") }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">{{ $t("public.Save") }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    editData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let vaildateInput = (rule, value, callback) => {
	  if(!this.checkSpecialKey(value)) {
	    callback(new Error(this.$t('AppManage.SDKMonitorPage.SDKPackageNameReg')))
	  } else {
	    callback()
	  }
  }
    return {
      editSDK:null,//编辑接收数据
      ruleForm: {
        name: "",
        packageName: "",
      },
      rules: {
        name: [
          { required: true, message: this.$t('AppManage.SDKMonitorPage.SDKNameVoid'), trigger: 'blur' },
            { min: 1, max: 20, message: this.$t('AppManage.SDKMonitorPage.SDKNameStr'), trigger: 'blur' },
        ],
        packageName: [
            { required: true, message: this.$t('AppManage.SDKMonitorPage.SDKPackageNameVoid'), trigger: 'blur' },
            { min: 1, max: 50, message: this.$t('AppManage.SDKMonitorPage.SDKPackageNameStr'), trigger: 'blur'},
            {  validator: vaildateInput,  trigger: 'blur'}
        ],
  
      },
      list: {
        S_SMS_ACCESS: {
          //读取短信记录
          forbid: "0", //禁止 1-勾选 0-未勾选， 以下同
          collect: "0", //采集行为 16-勾选 0-未勾选， 以下同
          action: "0", //响应动作 4-告警提示，8-清除数据， 2-退出应用， 64-禁止访问  以下同
        },
        S_CONTACTS_ACCESS: {
          forbid: "0",
          collect: "0",
          action: "0",
        },
        S_LOCATION: {
          forbid: "0",
          collect: "0",
          action: "0",
        },
        S_GET_SDCARD: {
          forbid: "0",
          collect: "0",
          action: "0",
        },
        S_GET_DEVICE_INFO: {
          forbid: "0",
          collect: "0",
          action: "0",
        },
        S_GET_INSTALLED_APP: {
          forbid: "0",
          collect: "0",
          action: "0",
        },
        S_CAMERA: {
          forbid: "0",
          collect: "0",
          action: "0",
        },
        S_WIFI_SWITCH: {
          forbid: "0",
          collect: "0",
          action: "0",
        },
        S_BLUETOOTH_SWITCH: {
          forbid: "0",
          collect: "0",
          action: "0",
        },
      },
    };
  },
  // created(){
  //   if (this.editData) {
  //     this.ruleForm.name = this.editData.name; //SDK名称
  //     this.ruleForm.packageName = this.editData.content.packageName; //SDK包名
  //     this.list = this.editData.content; //整体SDK权限
  //     console.log(this.list,'------;created')
  //   }
  // },
  
  mounted() {
 
    if (this.editData!=null) {
      console.log(this.editData,'编辑回显数据')
      this.ruleForm.name = this.editData.name; //SDK名称
      this.ruleForm.packageName = this.editData.content.packageName; //SDK包名
      this.list =  this.editData.content; //整体SDK权限
    }
  },
  methods: {
     // 特殊字符校验
    checkSpecialKey(str) {
        // 以下特殊字符禁用
      let specialKey ="[`@~!#$^&*()=|{}':;'\\[\\]<>/?~！#￥%……&*（）——|{}【】‘；：”“'。，、？]‘'";
      for (let i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
        return false;
        }
      }
      return true;
    },
    checkbox(val) {
      console.log(val, "--val复选框");
      //    this.$emit("threat", this.permission);
    },
    checkboxRadio1(value) {
      this.list.S_SMS_ACCESS.action =
        value == this.list.S_SMS_ACCESS.action ? "" : value;
      console.log(this.list.S_SMS_ACCESS.action)
    },
    checkboxRadio2(value) {
      this.list.S_CONTACTS_ACCESS.action =
        value == this.list.S_CONTACTS_ACCESS.action ? "" : value;
    },
    checkboxRadio3(value) {
      this.list.S_LOCATION.action =
        value == this.list.S_LOCATION.action ? "" : value;
    },
    checkboxRadio4(value) {
      this.list.S_GET_SDCARD.action =
        value == this.list.S_GET_SDCARD.action ? "" : value;
    },
    checkboxRadio5(value) {
      this.list.S_GET_DEVICE_INFO.action =
        value == this.list.S_GET_DEVICE_INFO.action ? "" : value;
    },
    checkboxRadio6(value) {
      this.list.S_GET_INSTALLED_APP.action =
        value+'' == this.list.S_GET_INSTALLED_APP.action ? "" : value;
    },
    checkboxRadio7(value) {
      this.list.S_CAMERA.action =
        value == this.list.S_CAMERA.action ? "" : value;
    },
    checkboxRadio8(value) {
      this.list.S_WIFI_SWITCH.action =
        value == this.list.S_WIFI_SWITCH.action ? "" : value;
    },
    checkboxRadio9(value) {
      this.list.S_BLUETOOTH_SWITCH.action =
        value == this.list.S_BLUETOOTH_SWITCH.action ? "" : value;
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async save() { 
      if (this.editData!=null && this.editData.id) {
        var params = {
          id: this.editData.id,
          name: this.ruleForm.name, //SDK名称
          type: "sdk",
          level: "0",
          os: "All",
          content: {
            permission: {
              S_GET_INSTALLED_APP:
                Number(this.list.S_GET_INSTALLED_APP.forbid) +
                Number(this.list.S_GET_INSTALLED_APP.collect) +
                Number(this.list.S_GET_INSTALLED_APP.action),
              S_CONTACTS_ACCESS:
                Number(this.list.S_CONTACTS_ACCESS.forbid) +
                Number(this.list.S_CONTACTS_ACCESS.collect) +
                Number(this.list.S_CONTACTS_ACCESS.action),
              S_GET_DEVICE_INFO:
                Number(this.list.S_GET_DEVICE_INFO.forbid) +
                Number(this.list.S_GET_DEVICE_INFO.collect) +
                Number(this.list.S_GET_DEVICE_INFO.action),
              S_BLUETOOTH_SWITCH:
                Number(this.list.S_BLUETOOTH_SWITCH.forbid) +
                Number(this.list.S_BLUETOOTH_SWITCH.collect) +
                Number(this.list.S_BLUETOOTH_SWITCH.action),
              S_LOCATION:
                Number(this.list.S_LOCATION.forbid) +
                Number(this.list.S_LOCATION.collect) +
                Number(this.list.S_LOCATION.action),
              S_SMS_ACCESS:
                Number(this.list.S_SMS_ACCESS.forbid) +
                Number(this.list.S_SMS_ACCESS.collect) +
                Number(this.list.S_SMS_ACCESS.action),
              S_WIFI_SWITCH:
                Number(this.list.S_WIFI_SWITCH.forbid) +
                Number(this.list.S_WIFI_SWITCH.collect) +
                Number(this.list.S_WIFI_SWITCH.action),
              S_GET_SDCARD:
                Number(this.list.S_GET_SDCARD.forbid) +
                Number(this.list.S_GET_SDCARD.collect) +
                Number(this.list.S_GET_SDCARD.action),
              S_CAMERA:
                Number(this.list.S_CAMERA.forbid) +
                Number(this.list.S_CAMERA.collect) +
                Number(this.list.S_CAMERA.action),
            },
            //SDK权限信息
            packageName: this.ruleForm.packageName, //SDK包名
            S_SMS_ACCESS: this.list.S_SMS_ACCESS,
            S_CONTACTS_ACCESS: this.list.S_CONTACTS_ACCESS,
            S_LOCATION: this.list.S_LOCATION,
            S_GET_SDCARD: this.list.S_GET_SDCARD,
            S_GET_DEVICE_INFO: this.list.S_GET_DEVICE_INFO,
            S_GET_INSTALLED_APP: this.list.S_GET_INSTALLED_APP,
            S_CAMERA: this.list.S_CAMERA,
            S_WIFI_SWITCH: this.list.S_WIFI_SWITCH,
            S_BLUETOOTH_SWITCH: this.list.S_BLUETOOTH_SWITCH,
          },
        };
        console.log(params.content.permission, "----新增保存");
        let arr = []
        for (var key in params.content.permission) {
          // console.log(formData[key], 'keySDK')
          arr.push(params.content.permission[key])
          // console.log(a, 'aaa')
        }
        const allZeros = arr.every(item => item === 0)
        if (allZeros) {
          this.$message({
            message: this.$t('AppManage.SDKMonitorPage.SDKPermissInfoVoid'),
            type: 'error',
            offset: 100
          })
        } else {
          const res = await this.$axios.post("/httpServe/monitorExSDKRule/update",params,true);
          this.$emit("sdkSave", res.data);
        }
        
      } else {
        var params = {
          name: this.ruleForm.name, //SDK名称
          type: "sdk",
          level: "0",
          os: "All",
          content: {
            permission: {
              S_GET_INSTALLED_APP:
                Number(this.list.S_GET_INSTALLED_APP.forbid) +
                Number(this.list.S_GET_INSTALLED_APP.collect) +
                Number(this.list.S_GET_INSTALLED_APP.action),
              S_CONTACTS_ACCESS:
                Number(this.list.S_CONTACTS_ACCESS.forbid) +
                Number(this.list.S_CONTACTS_ACCESS.collect) +
                Number(this.list.S_CONTACTS_ACCESS.action),
              S_GET_DEVICE_INFO:
                Number(this.list.S_GET_DEVICE_INFO.forbid) +
                Number(this.list.S_GET_DEVICE_INFO.collect) +
                Number(this.list.S_GET_DEVICE_INFO.action),
              S_BLUETOOTH_SWITCH:
                Number(this.list.S_BLUETOOTH_SWITCH.forbid) +
                Number(this.list.S_BLUETOOTH_SWITCH.collect) +
                Number(this.list.S_BLUETOOTH_SWITCH.action),
              S_LOCATION:
                Number(this.list.S_LOCATION.forbid) +
                Number(this.list.S_LOCATION.collect) +
                Number(this.list.S_LOCATION.action),
              S_SMS_ACCESS:
                Number(this.list.S_SMS_ACCESS.forbid) +
                Number(this.list.S_SMS_ACCESS.collect) +
                Number(this.list.S_SMS_ACCESS.action),
              S_WIFI_SWITCH:
                Number(this.list.S_WIFI_SWITCH.forbid) +
                Number(this.list.S_WIFI_SWITCH.collect) +
                Number(this.list.S_WIFI_SWITCH.action),
              S_GET_SDCARD:
                Number(this.list.S_GET_SDCARD.forbid) +
                Number(this.list.S_GET_SDCARD.collect) +
                Number(this.list.S_GET_SDCARD.action),
              S_CAMERA:
                Number(this.list.S_CAMERA.forbid) +
                Number(this.list.S_CAMERA.collect) +
                Number(this.list.S_CAMERA.action),
            },
            //SDK权限信息
            packageName: this.ruleForm.packageName, //SDK包名
            S_SMS_ACCESS: this.list.S_SMS_ACCESS,
            S_CONTACTS_ACCESS: this.list.S_CONTACTS_ACCESS,
            S_LOCATION: this.list.S_LOCATION,
            S_GET_SDCARD: this.list.S_GET_SDCARD,
            S_GET_DEVICE_INFO: this.list.S_GET_DEVICE_INFO,
            S_GET_INSTALLED_APP: this.list.S_GET_INSTALLED_APP,
            S_CAMERA: this.list.S_CAMERA,
            S_WIFI_SWITCH: this.list.S_WIFI_SWITCH,
            S_BLUETOOTH_SWITCH: this.list.S_BLUETOOTH_SWITCH,
          },
        };
        let arr = []
        for (var key in params.content.permission) {
          // console.log(formData[key], 'keySDK')
          arr.push(params.content.permission[key])
          // console.log(a, 'aaa')
        }
        const allZeros = arr.every(item => item === 0)
        if (allZeros) {
          this.$message({
            message: this.$t('AppManage.SDKMonitorPage.SDKPermissInfoVoid'),
            type: 'error',
            offset: 100
          })
        } else {
          console.log(params, "----新增保存");
          const res = await this.$axios.post(
            "/httpServe/monitorExSDKRule/save",
            params,
            true
          );
        this.$emit("sdkSave", res.data);
        }

      }
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep  .sdk_Permissions>.el-form-item__content{
      width: 900px;
      box-sizing: border-box;
      padding-top: 10px;
      font-size: 12px !important;
      border: 1px solid #dde0e7;
  }
  ::v-deep  .sdk_Permissions>.el-form-item__content .el-form-item__label{
      color: black;
      font-weight: bold;
      font-size: 12px !important;
  }
  ::v-deep  .sdk_Permissions .el-checkbox__label{
      font-size: 12px !important;
  }
  ::v-deep  .sdk_Permissions .el-radio__label{
      font-size: 12px !important;
  }
</style>