<template>
  <div class="import">
    <div class="importPage">
      <el-upload
        style="margin-left: 200px"
        ref="form"
        accept=".xls,.xlsx"
        :data="form"
        :limit="1"
        :on-exceed="handleExceed"
        :action="'/httpServe/monitorExSDKRule/extractFile'"
        :before-upload="beforeUpload"
        :auto-upload="false"
        :on-success="handleSuccess"
        :on-change="uploadChange"
        :multiple="true"
        drag
        class="el-upload1"
      >
        <div class="left">
          <el-button size="small" type="primary">{{
            $t("AppManage.SDKMonitorPage.SelectFile")
          }}</el-button>
          <div slot="tip" class="el-upload__tip">
            {{ $t("AppManage.SDKMonitorPage.FileDrag") }}
          </div>
        </div>

        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          {{ $t("AppManage.SDKMonitorPage.DragFileHereOr")
          }}<em>{{ $t("AppManage.SDKMonitorPage.ClickUpload") }}</em>
        </div>
      </el-upload>
      <el-progress style="margin-left: 200px;width: 360px" v-if="flagStatus" :percentage="percentage" :color="customColorMethod"></el-progress>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        :loading="loading"
        @click="submitUpload"
        size="small"
        class="save"
        >{{ $t("public.Save") }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        filename: "",
      },
      saveFlag: false,
      loading: false,
      flagStatus: false,
      percentage: 0,
    };
  },
  components: {},
  mounted() {},
  methods: {
    // 文件限制
    handleExceed(files, fileList) {
      this.$message.warning(this.$t("AppManage.SDKMonitorPage.OnlyOneFile"));
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return '#909399'
      } else if (percentage < 70) {
        return '#e6a23c'
      } else {
        return '#67c23a'
      }
    },
    //导入保存
    async uploadChange(file, fileList) {
      this.percentage = 0;
      this.flagStatus = true;
      // 获取上传的文件
      var files = file;
      // 判断上传的文件是否是 以apk结尾的apk文件
      if (
        files.name.substr(files.name.length - 3) === "xls" ||
        files.name.substr(files.name.length - 4) === "xlsx"
      ) {
        let formData = new FormData();
        formData.append("file", file.raw);
        formData.append("fileId", "file");
        // debugger
        const res = await this.$axios.upload(
          "/httpServe/monitorExSDKRule/extractFile",
          formData,
          this.updateProgress,
          true
        );
        // .then(() => {
        // debugger
        console.log(res, "res.data == -1");
        if (res.data.code == 200 && res.data.data == 1) {
          this.$message({
            message: this.$t("AppManage.SDKMonitorPage.ImportSuccessful"),
            type: "success",
            offset: 100,
          });
          this.saveFlag = true;
          this.flagStatus = false;
        }
        if (res.data.code == 200 && res.data.data == -1) {
          this.$message({
            message: this.$t("AppManage.SDKMonitorPage.fileAlreadyExist"),
            type: "error",
            offset: 100,
          });
          this.saveFlag = false;
          this.flagStatus = false;
          this.$refs.form.clearFiles();
        }
      }else{
        this.$message({
          message: this.$t("AppManage.SDKMonitorPage.RequiredFile"),
          offset: 100,
          type: 'error',
        })
        this.saveFlag = false;
        this.flagStatus = false;
        this.$refs.form.clearFiles();
        return false;
      }
    },
    updateProgress(e) {
      //e为回调回来的参数 通过进行和total的值来进行进度
       this.percentage = parseInt((e.loaded / e.total) * 100)
    },
    // 保存上传
    async submitUpload() {
      if (this.saveFlag) {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.$emit("sdkSave", 1);
      } else {
        this.$message({
          message: this.$t("AppManage.SDKMonitorPage.PleaseUpload"),
          type: "error",
          offset: 100,
        });
      }
    },
    beforeUpload(file) {
      let isLt2M = true;
      isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.loading = false;
        this.$message({
          message: this.$t("AppManage.SDKMonitorPage.UploadFileSize"),
          type: "error",
          offset: 100,
        });
      }
      this.filename = file.name;
      return isLt2M;
    },
    handleSuccess(response, file, fileList) {
      this.$refs.form.clearFiles();
      this.$message({
        message: this.$t("AppManage.SDKMonitorPage.ImportSuccessful"),
        type: "success",
        offset: 100,
      });
    },
  },
};
</script>
<style scoped lang='scss'>
//保存
.save {
  margin: 20px 0 0 200px;
}
</style>